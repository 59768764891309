@import "_includes.scss";
@import "_bootstrap_custom";
// @import './bootswatch/cyborg';
@import "_util.scss";

html {
  font-size: $font-size-absolute;
}

.bg-none {
  background: none;
}

th.vertical {
  text-align: center;
  span {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
  }
}
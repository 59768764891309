@import "../_includes.scss";

.container.async-commands {
    .commands-list {
        .command-group {
            font-size: 0.85rem;
            margin-bottom: 1.5em;
        }
        h4 {
            font-size: 1em;
            font-weight: 600;
            margin: 0 0 0.5rem;
        }
        pre {
            font-size: 1em;
            margin: 0 0 0.5rem;
            font-style: italic;
            code {
                font-style: normal;
                display: inline-block;
                background-color: $gray-800;
                padding: 0.4em 0.8em;
                border-radius: $border-radius-sm;
                border: 1px $gray-600 solid;
                // margin-right: 0.8rem;
            }
            em {
                font-style: normal;
                font-weight: 300;
                opacity: 0.66;
            }
        }
        .help-text {
            font-size: 1em;
            line-height: 1.4;
            margin-top: 0;
            margin: 0 0 0.5em;
            padding: 0.2em 0 0.2em 1em;
            box-shadow: 1px 0 0 0 inset $gray-600;
            white-space: pre-wrap;
        }
    }
}
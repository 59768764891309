@import './bootswatch/cyborg_variables';

$enable-negative-margins: true;

$font-size-absolute: 16px;
$small-font-size: 0.8rem;
$font-size-base: 1rem;

$grid-gutter-width: $font-size-absolute * 1.5;

$headings-font-weight: 200;
$headings-line-height: 1;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: 1em;

$font-family-monospace: 'Roboto Mono', monospace;
$font-family-sans-serif: $font-family-monospace;

$body-color: $gray-200;

$input-bg: $body-bg;
$input-color: $body-color;
$input-border-width: 1px;
$input-border-color: $dark;

$modal-inner-padding: 1rem;
$modal-header-padding-y: 0.5rem;
$modal-footer-padding-y: $modal-header-padding-y;
@import "../_includes.scss";

.faction-summary {
    overscroll-behavior: none;

    &.container {
        &.select-factions {
            max-width: map-get($container-max-widths, sm);
        }
        &.summary-results {
            // max-width: 1200px;
        }
    }

    .faction-summary-images {
        list-style: none;
        padding: 0;
        // overflow: auto;
        // li {
        //     display: block;
        //     width: 100%;
        //     margin: 0 0 $spacer 0;
        // }
        .faction-row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            height: 65vw;
            max-height: 95vh;
            margin-bottom: $spacer;
            align-items: center;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .image-cell {
            display: inline-block;
            height: 100%;
            width: auto;
            margin-right: $spacer;
            &:last-child {
                margin-left: 0;
            }
        }

        &.single-faction {
            .faction-row {
                max-height: 100vh;
            }
        }
    }

    .faction-link {
        line-height: 24px;
        vertical-align: middle;
        margin-left: 0.5em;
        display: inline-block;
    }
}